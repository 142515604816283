import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import * as otg from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";
import CustomButton from "../../CustomButton";

const OurTeamGrowingSection = ({data}) => {

    const data_list = data.our_team_right;


    function handleClick(){
        window.location = '/we-are-hiring'
    }


    return (
        <section className={otg.our_team_growing_section}>
            <Container>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <div className={otg.our_team_growing_content_box}>

                            {/*Our Team Title*/}
                            <div className={otg.our_team_growing_content_box_title}>
                                <PrismicRichText field={data.our_team_title}/>
                            </div>
                            {/*Our Team Description*/}
                            <div className={otg.our_team_growing_content_box_parm}>
                                <PrismicRichText field={data.our_team_description}/>
                            </div>
                            {/*Our Team Button Text*/}
                            <Button className={otg.our_team_growing_content_box_button}
                                    type="button"
                                    variant="primary"
                                    onClick={handleClick}
                            >
                                {data.our_team_button_text}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <div className={otg.our_team_growing_content_box}>
                            <Row>
                                <Col className={otg.our_team_growing_content_box_cel} xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>

                                    {/*Our Team Left Description*/}
                                    <div className={otg.our_team_growing_content_box_cel_parm}>
                                        <PrismicRichText field={data.our_team_left_description}/>
                                    </div>
                                </Col>
                                <Col className={otg.our_team_growing_content_box_cel_2} xxxl={6} xxl={6} xl={6}
                                     lg={6}
                                     md={6} sm={12} xs={12} xxs={12}>
                                    <ul className={otg.our_team_growing_content_box_cel_ul}>
                                        {
                                            data_list.map((item, idx) => {
                                                return(
                                                    <li>
                                                        🔥 {item.our_team_right_list}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default OurTeamGrowingSection;
