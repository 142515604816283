import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import HeroSection from "../../components/AboutUs/HeroSection";
import OurTeamSection from "../../components/AboutUs/OurTeamSection";
import OurPurposeSection from "../../components/AboutUs/OurPurpose";
import OurTeamGrowingSection from "../../components/AboutUs/OurTeamGrowing";
import OurPresenceSection from "../../components/AboutUs/OurPresence";
import get from "lodash/get";
import {graphql} from "gatsby";

const AboutUs= ({ data, location }) => {

    const pageData = get(data, "prismicAboutUs.dataRaw", {});
    console.log(pageData);

    const hero = {
        hero_title: get(pageData, "hero_title", []),
        hero_side_image: get(pageData, "hero_side_image.url", ""),
        hero_button_text: get(pageData, "hero_button_text", ""),
        hero_side_image_alt: get(pageData, "hero_side_image.alt", ""),
    }

    const purpose = {
        our_purpose_description: get(pageData, "our_purpose_description", []),
        our_purpose_title: get(pageData, "our_purpose_title", [])
    }

    const growing = {
        our_team_title: get(pageData, "our_team_title", []),
        our_team_right: get(pageData, "our_team_right", []),
        our_team_left_description: get(pageData, "our_team_left_description", []),
        our_team_description: get(pageData, "our_team_description", []),
        our_team_button_text: get(pageData, "our_team_button_text", [])
    }

    const team = {
        team_person_1_facebook: get(pageData, "team_person_1_facebook", []),
        team_person_1_image: get(pageData, "team_person_1_image.url", []),
        team_person_1_instagram: get(pageData, "team_person_1_instagram", []),
        team_person_1_linkedin: get(pageData, "team_person_1_linkedin", []),
        team_person_1_name: get(pageData, "team_person_1_name", []),
        team_person_1_position: get(pageData, "team_person_1_position", []),
        team_person_1_twitter: get(pageData, "team_person_1_twitter", []),

        team_person_2_facebook: get(pageData, "team_person_2_facebook", []),
        team_person_2_image: get(pageData, "team_person_2_image.url", []),
        team_person_2_instagram: get(pageData, "team_person_2_instagram", []),
        team_person_2_linkedin: get(pageData, "team_person_2_linkedin", []),
        team_person_2_name: get(pageData, "team_person_2_name", []),
        team_person_2_position: get(pageData, "team_person_2_position", []),
        team_person_2_twitter: get(pageData, "team_person_2_twitter", []),

        team_person_3_facebook: get(pageData, "team_person_3_facebook", []),
        team_person_3_image: get(pageData, "team_person_3_image.url", []),
        team_person_3_instagram: get(pageData, "team_person_3_instagram", []),
        team_person_3_linkedin: get(pageData, "team_person_3_linkedin", []),
        team_person_3_name: get(pageData, "team_person_3_name", []),
        team_person_3_position: get(pageData, "team_person_3_position", []),
        team_person_3_twitter: get(pageData, "team_person_3_twitter", []),

        team_person_4_facebook: get(pageData, "team_person_4_facebook", []),
        team_person_4_image: get(pageData, "team_person_4_image.url", []),
        team_person_4_instagram: get(pageData, "team_person_4_instagram", []),
        team_person_4_linkedin: get(pageData, "team_person_4_linkedin", []),
        team_person_4_name: get(pageData, "team_person_4_name", []),
        team_person_4_position: get(pageData, "team_person_4_position", []),
        team_person_4_twitter: get(pageData, "team_person_4_twitter", []),
    }

    return (
        <Layout location={location} title={''}>
            <HeroSection data={hero}/>
            <OurTeamSection data={team}/>
            <OurPurposeSection data={purpose}/>
            <OurPresenceSection/>
            <OurTeamGrowingSection data={growing}/>
        </Layout>
    );
};

export const Head = ({ data }) => {
    return <Seo title={'About Us'} description={'excerpt'} />;
};
export default AboutUs;


export const query = graphql`
  query seoPersonalInsuranceQuery{
    prismicAboutUs{
      dataRaw
    }
  }
`