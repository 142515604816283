// extracted by mini-css-extract-plugin
export var com_cel = "index-module--com_cel--2c6a6";
export var our_team_content = "index-module--our_team_content--41f61";
export var our_team_section = "index-module--our_team_section--ebb9b";
export var our_team_section_content_box = "index-module--our_team_section_content_box--7e88c";
export var our_team_section_content_box_profile = "index-module--our_team_section_content_box_profile--b7842";
export var our_team_section_content_box_title = "index-module--our_team_section_content_box_title--106af";
export var our_team_section_team_box = "index-module--our_team_section_team_box--b3c5a";
export var our_team_section_team_box_social_link = "index-module--our_team_section_team_box_social_link--29f36";
export var our_team_section_team_box_social_link_3 = "index-module--our_team_section_team_box_social_link_3--c773c";
export var our_team_section_team_profile = "index-module--our_team_section_team_profile--12182";
export var social_link = "index-module--social_link--e383a";