import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HomePagePerson from "../../../images/extended_team/hero/next3zeros_home_hero_person.png";
import ButtonArrow from "../../../images/common/next3zeros_button_arrow_right.svg";
import * as style from "./index.module.scss";
import CustomButton from "../../CustomButton";
import {PrismicRichText} from "@prismicio/react";

const HeroSection = ({data}) => {

    console.log({data})

    function handleClick(){
        window.location = '/contact-us'
    }

    return (
        <header className={style.hero_section}>
            <div className={style.hero_wrapper}>
                <Container className={style.hero_container}>
                    <div className={style.box}>
                        <img className={style.hero_img}
                             src={data.hero_side_image}
                             alt={data.hero_side_image_alt}
                        />
                        <div className={style.content_box}>
                            <div>
                                <PrismicRichText field={data.hero_title}/>
                            </div>
                            <CustomButton
                                label={data.hero_button_text}
                                prefixImage={null}
                                suffixImage={ButtonArrow}
                                className={style.hero_button}
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default HeroSection;
