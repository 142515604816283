import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import next3zeros_about_us_facebook from "../../../images/about_us/social_media/next3zeros_about_us_facebook.svg";
import next3zeros_about_us_instagram from "../../../images/about_us/social_media/next3zeros_about_us_instagram.svg";
import next3zeros_about_us_linked_in from "../../../images/about_us/social_media/next3zeros_about_us_linked_in.svg";
import next3zeros_about_us_twitter from "../../../images/about_us/social_media/next3zeros_about_us_twitter.svg";
import next3zeros_chief_executive_officer
    from "../../../images/about_us/team_member/next3zeros_chief_executive_officer.svg";
import next3zeros_chief_marketing_officer
    from "../../../images/about_us/team_member/next3zeros_chief_marketing_officer.svg";
import next3zeros_chief_marketing_consultant
    from "../../../images/about_us/team_member/next3zeros_chief_marketing_consultant.svg";
import next3zeros_human_resources_officer
    from "../../../images/about_us/team_member/next3zeros_human_resources_officer.svg";
import * as ot from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";

const OurTeamSection = ({data}) => {

    const data_team = data.team_card;
    console.log(data_team);

    return (
        <section className={ot.our_team_section}>
            <Container className={ot.com_container}>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className={ot.com_cel}>
                        <div className={ot.our_team_section_content_box}>
                            {/*Our Team Title*/}
                            <div className={ot.our_team_section_content_box_title}>
                                <PrismicRichText field={data.team_title}/>
                            </div>
                        </div>
                    </Col>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className={ot.com_cel}>
                        <div
                            className={`${ot.our_team_section_content_box} ${ot.our_team_section_content_box_profile}`}>
                            <Row>

                                <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} xxs={12}
                                     className={ot.com_cel}>
                                    <div className={ot.our_team_section_team_box}>
                                        <a className={ot.our_team_section_team_profile} href="#">
                                            {/*Our Team Person 1 Image*/}
                                            <img src={data.team_person_1_image}/>
                                        </a>
                                        <div className={ot.our_team_content}>

                                            {/*Our Team Person 1 Position*/}
                                            <PrismicRichText field={data.team_person_1_position}/>

                                            {/*Our Team Person 1 Name*/}
                                            <PrismicRichText field={data.team_person_1_name}/>

                                            <ul className={ot.our_team_section_team_box_social_link}>
                                                <li>
                                                    <a className={ot.social_link} href={data.team_person_1_linkedin}>
                                                        <img src={next3zeros_about_us_linked_in}/>
                                                    </a>
                                                </li>
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_1_facebook}>*/}
                                                        {/*<img src={next3zeros_about_us_facebook}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_1_instagram}>*/}
                                                        {/*<img src={next3zeros_about_us_instagram}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_1_twitter}>*/}
                                                        {/*<img src={next3zeros_about_us_twitter}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} xxs={12}
                                     className={ot.com_cel}>
                                    <div className={ot.our_team_section_team_box}>
                                        <a className={ot.our_team_section_team_profile} href="#">

                                            <img src={data.team_person_2_image}/>

                                        </a>
                                        <div className={ot.our_team_content}>

                                            <PrismicRichText field={data.team_person_2_position}/>

                                            <PrismicRichText field={data.team_person_2_name}/>

                                            <ul className={ot.our_team_section_team_box_social_link}>
                                                <li>
                                                    <a className={ot.social_link} href={data.team_person_2_linkedin}>
                                                        <img src={next3zeros_about_us_linked_in}/>
                                                    </a>
                                                </li>
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_2_facebook}>*/}
                                                        {/*<img src={next3zeros_about_us_facebook}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_2_instagram}>*/}
                                                        {/*<img src={next3zeros_about_us_instagram}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_2_twitter}>*/}
                                                        {/*<img src={next3zeros_about_us_twitter}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} xxs={12}
                                     className={ot.com_cel}>
                                    <div className={ot.our_team_section_team_box}>
                                        <a className={ot.our_team_section_team_profile} href="#">
                                            <img src={data.team_person_3_image}/>
                                        </a>

                                        <div className={ot.our_team_content}>

                                            <PrismicRichText field={data.team_person_3_position}/>

                                            <PrismicRichText field={data.team_person_3_name}/>

                                            <ul className={ot.our_team_section_team_box_social_link}>
                                                <li>
                                                    <a className={ot.social_link} href={data.team_person_3_linkedin}>
                                                        <img src={next3zeros_about_us_linked_in}/>
                                                    </a>
                                                </li>
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_3_facebook}>*/}
                                                        {/*<img src={next3zeros_about_us_facebook}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_3_instagram}>*/}
                                                        {/*<img src={next3zeros_about_us_instagram}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_3_twitter}>*/}
                                                        {/*<img src={next3zeros_about_us_twitter}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxxl={12} xxl={12} xl={12} lg={12} md={6} sm={12} xs={12} xxs={12}
                                     className={`${ot.com_cel} ${ot.com_cel_4}`}>
                                    <div
                                        className={ot.our_team_section_team_box }>
                                        <a className={ot.our_team_section_team_profile} href="#">

                                            <img src={data.team_person_4_image}/>

                                        </a>
                                        <div className={ot.our_team_content}>

                                            <PrismicRichText field={data.team_person_4_position}/>

                                            <PrismicRichText field={data.team_person_4_name}/>

                                            <ul className={`${ot.our_team_section_team_box_social_link} ${ot.our_team_section_team_box_social_link_3}`}>
                                                <li>
                                                    <a className={ot.social_link} href={data.team_person_4_linkedin}>
                                                        <img src={next3zeros_about_us_linked_in}/>
                                                    </a>
                                                </li>
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_4_facebook}>*/}
                                                        {/*<img src={next3zeros_about_us_facebook}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_4_instagram}>*/}
                                                        {/*<img src={next3zeros_about_us_instagram}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={ot.social_link} href={data.team_person_4_twitter}>*/}
                                                        {/*<img src={next3zeros_about_us_twitter}/>*/}
                                                    {/*</a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>

                                    </div>
                                </Col>
                                <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} xxs={12}
                                     className={ot.com_cel}></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default OurTeamSection;
