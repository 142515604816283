import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import next3zeros_our_presence from "../../../images/about_us/our_presence/next3zeros_our_presence.svg";
import Country_1 from "../../../images/about_us/our_presence/next3zeros_country_1.svg";
import Country_2 from "../../../images/about_us/our_presence/next3zeros_country_2.svg";
import Country_3 from "../../../images/about_us/our_presence/next3zeros_country_3.svg";
import Country_4 from "../../../images/about_us/our_presence/next3zeros_country_4.svg";
import Country_5 from "../../../images/about_us/our_presence/next3zeros_country_5.svg";
import * as he from "./index.module.scss";


const OurPresenceSection = () => {

    return (
        <section className={`our_presence_section ${he.our_presence_section}`}>
            <Container>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {/*Our Presence Title*/}
                        <h1>
                            Our Presence
                        </h1>
                    </Col>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <label className={`${he.country_view} ${he.country_view_1}`}>
                            <img className={he.map_img_flag} src={Country_1} />Australia
                        </label>
                        <label className={`${he.country_view} ${he.country_view_2}`}>
                            <img className={he.map_img_flag} src={Country_2} />Singapore
                        </label>
                        <label className={`${he.country_view} ${he.country_view_3}`}>
                            <img className={he.map_img_flag} src={Country_3} />Sri Lanka
                        </label>
                        <label className={`${he.country_view} ${he.country_view_4}`}>
                            <img className={he.map_img_flag} src={Country_4} />United States
                        </label>
                        <label className={`${he.country_view} ${he.country_view_5}`}>
                            <img className={he.map_img_flag} src={Country_5} />The Netherlands
                        </label>
                        <img className={he.map_img} src={next3zeros_our_presence} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default OurPresenceSection;
