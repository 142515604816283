import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as wwd from './index.module.scss';
import {PrismicRichText} from "@prismicio/react";

const OurPurposeSection = ({data}) => {

    return (
        <section className={wwd.why_do_section}>
            <Container>

                <div className="animation-container">
                    <div className="circle one"></div>
                    <div className="circle two"></div>
                    <div className="circle three"></div>
                    <div className="circle four"></div>
                </div>

                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <div className={wwd.why_do_section_content}>
                            {/*Our Purpose Title*/}
                            <div className={wwd.why_do_section_title}>
                                <PrismicRichText field={data.our_purpose_title}/>
                            </div>
                            {/*Our Purpose Description*/}
                            <div className={wwd.why_do_section_parm}>
                                <PrismicRichText field={data.our_purpose_description}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};


export default OurPurposeSection;